import * as app from './app/actions';
import * as areaCodes from './areaCodes/actions';
import * as customers from './customers/actions';
import * as groups from './groups/actions';
import * as numbering from './numbering/actions';
import * as phoneextensions from './phoneextensions/actions';
import * as phonenumbers from './phonenumbers/actions';
import * as queues from './queues/actions';
import * as toasts from './toasts/actions';
import * as timeintervals from './timeintervals/actions';
import * as user from './user/actions';
import * as ivrs from './ivrs/actions';
import * as billing from './billing/actions';
import * as sounds from './sounds/actions';
import * as branding from './branding/actions';
import * as virtuals from './virtuals/actions';
import * as mailboxes from './mailboxes/actions';
import * as trunks from './trunks/actions';
import * as linkedusers from './linkeduser/actions';
import * as forwardingrules from './forwardingrules/actions';
import * as registrations from './registrations/actions';
import * as creditstatus from './creditstatus/actions';
import * as callbundles from './callbundles/actions';
import * as phonebook from './phonebook/actions';
import * as permissions from './permissions/actions';
import * as limits from './limits/actions';
import * as pricing from './pricing/actions';
import * as callcharging from './callcharging/actions';
import * as routingrules from './routingrules/actions';
import * as preferences from './preferences/actions';
import * as callrecordings from './callrecordings/actions';
import * as bargegroups from './bargegroups/actions';
import * as countries from './countries/actions';
import * as blockingrules from './blockingrules/actions';
import * as provisioning from './provisioning/actions';

export {
  app,
  areaCodes,
  customers,
  groups,
  numbering,
  phoneextensions,
  phonenumbers,
  phonebook,
  queues,
  toasts,
  timeintervals,
  user,
  ivrs,
  billing,
  sounds,
  branding,
  virtuals,
  mailboxes,
  trunks,
  linkedusers,
  forwardingrules,
  registrations,
  creditstatus,
  callbundles,
  permissions,
  limits,
  pricing,
  callcharging,
  routingrules,
  preferences,
  callrecordings,
  bargegroups,
  countries,
  blockingrules,
  provisioning,
};

export const App = {
  closeSidebar: 'CLOSE_SIDEBAR',
  modalClosed: 'MODAL_CLOSED',
  modalOpened: 'MODAL_OPENED',
  openSidebar: 'OPEN_SIDEBAR',
  toggleSidebar: 'TOGGLE_SIDEBAR',
};
export const Branding = {
  receive: 'BRANDING_RECEIVE',
};

export const AreaCodes = {
  clear: 'AREACODES_CLEAR',
  fetching: 'AREACODES_FETCHING',
  fetchingDone: 'AREACODES_FETCHING_DONE',
  receive: 'AREACODES_RECEIVE',
};

export const Customers = {
  clear: 'CUSTOMERS_CLEAR',
  fetching: 'CUSTOMERS_FETCH',
  fetchingDone: 'CUSTOMERS_FETCHING_DONE',
  receive: 'CUSTOMERS_RECEIVE',
  saving: 'CUSTOMERS_SAVING',
  savingDone: 'CUSTOMERS_SAVING_DONE',
};

export const Extensions = {
  clear: 'EXTENSIONS_CLEAR',
  fetching: 'EXTENSIONS_FETCHING',
  fetchingDone: 'EXTENSIONS_FETCHING_DONE',
  receive: 'EXTENSIONS_RECEIVE',
  // registrations: {
  //   clear: 'EXTENSIONS_REGISTRATIONS_CLEAR',
  //   fetching: 'EXTENSIONS_REGISTRATIONS_FETCHING',
  //   fetchingDone: 'EXTENSIONS_REGISTRATIONS_FETCHING_DONE',
  //   receive: 'EXTENSIONS_REGISTRATIONS_RECEIVE',
  // },
  saving: 'EXTENSIONS_SAVING',
  savingDone: 'EXTENSION_SAVING_DONE',
};

export const RingGroup = {
  clear: 'RINGGROUP_CLEAR',
  fetching: 'RINGGROUP_FETCHING',
  fetchingDone: 'RINGGROUP_FETCHING_DONE',
  receive: 'RINGGROUP_RECEIVE',
  remove: 'RINGGROUP_REMOVE',
  saving: 'RINGGROUP_SAVING',
  savingDone: 'RINGGROUP_SAVING_DONE',
  updateValidity: 'RINGROUP_UPDATE_VALIDITY',
};

export const Numbering = {
  clear: 'NUMBERING_CLEAR',
  fetching: 'NUMBERING_FETCHING',
  fetchingDone: 'NUMBERING_FETCHING_DONE',
  receive: 'NUMBERING_RECEIVE',
};

export const PhoneNumbers = {
  clear: 'PHONENUMBERS_CLEAR',
  fetching: 'PHONENUMBERS_FETCHING',
  fetchingDone: 'PHONENUMBERS_FETCHING_DONE',
  receive: 'PHONENUMBERS_RECEIVE',
  remove: 'PHONENUMBERS_REMOVE',
  saving: 'PHONENUMBERS_SAVING',
  savingDone: 'PHONENUMBERS_SAVING_DONE',
  updateValidity: 'PHONENUMBERS_UPDATE_VALIDITY',
};

export const CallQueue = {
  clear: 'CALLQUEUE_CLEAR',
  fetching: 'CALLQUEUE_FETCHING',
  fetchingDone: 'CALLQUEUE_FETCHING_DONE',
  receive: 'CALLQUEUE_RECEIVE',
  remove: 'CALLQUEUE_REMOVE',
  saving: 'CALLQUEUE_SAVING',
  savingDone: 'CALLQUEUE_SAVING_DONE',
  updateValidity: 'CALLQUEUE_UPDATE_VALIDITY',
};

export const PhoneExtension = {
  clear: 'PHONEEXTENSION_CLEAR',
  fetching: 'PHONEEXTENSION_FETCHING',
  fetchingDone: 'PHONEEXTENSION_FETCHING_DONE',
  receive: 'PHONEEXTENSION_RECEIVE',
  remove: 'PHONEEXTENSION_REMOVE',
  saving: 'PHONEEXTENSION_SAVING',
  savingDone: 'PHONEEXTENSION_SAVING_DONE',
  updateValidity: 'PHONEXTENSION_UPDATE_VALIDITY',
};

export const Virtual = {
  clear: 'VIRTUAL_CLEAR',
  fetching: 'VIRTUAL_FETCHING',
  fetchingDone: 'VIRTUAL_FETCHING_DONE',
  receive: 'VIRTUAL_RECEIVE',
  remove: 'VIRTUAL_REMOVE',
  saving: 'VIRTUAL_SAVING',
  savingDone: 'VIRTUAL_SAVING_DONE',
  updateValidity: 'VIRTUAL_UPDATE_VALIDITY',
};

export const TimeIntervals = {
  clear: 'TIMEINTERVAL_CLEAR',
  fetching: 'TIMEINTERVAL_FETCHING',
  fetchingDone: 'TIMEINTERVAL_FETCHING_DONE',
  receive: 'TIMEINTERVAL_RECEIVE',
  remove: 'TIMEINTERVAL_REMOVE',
  saving: 'TIMEINTERVAL_SAVING',
  savingDone: 'TIMEINTERVAL_SAVING_DONE',
};

export const Toasts = {
  clear: 'TOASTS_CLEAR',
  preremove: 'TOASTS_PREREMOVE',
  push: 'TOASTS_PUSH',
  remove: 'TOASTS_REMOVE',
  shift: 'TOASTS_SHIFT',
};

export const User = {
  authenticating: 'USER_AUTHENTICATING',
  authenticatingDone: 'USER_AUTHENTICATING_DONE',
  clear: 'USER_CLEAR',
  fetching: 'USER_FETCHING',
  fetchingDone: 'USER_FETCHING_DONE',
  login: 'USER_LOGIN',
  logout: 'USER_LOGOUT',
  receive: 'USER_RECEIVE',
  setActiveCustomer: 'USER_SET_ACTIVE_CUSTOMER',
};

export const IVRMenus = {
  clear: 'IVRMENUS_CLEAR',
  fetching: 'IVRMENUS_FETCHING',
  fetchingDone: 'IVRMENUS_FETCHING_DONE',
  receive: 'IVRMENUS_RECEIVE',
  remove: 'IVRMENUS_REMOVE',
  saving: 'IVRMENUS_SAVING',
  savingDone: 'IVRMENUS_SAVING_DONE',
  updateValidity: 'IVRMENUS_UPDATE_VALIDITY',
};

export const Billing = {
  clear: 'BILLING_CLEAR',
  fetching: 'BILLING_FETCHING',
  fetchingDone: 'BILLING_FETCHING_DONE',
  receive: 'BILLING_RECEIVE',
  remove: 'BILLING_REMOVE',
  saving: 'BILLING_SAVING',
  savingDone: 'BILLING_SAVING_DONE',
  // eslint-disable-next-line sort-keys
  estimate: {
    clear: 'BILLING_ESTIMATE_CLEAR',
    fetching: 'BILLING_ESTIMATE_FETCHING',
    fetchingDone: 'BILLING_ESTIMATE_FETCHING_DONE',
    receive: 'BILLING_ESTIMATE_RECEIVE',
  },
  paymentMethods: {
    clear: 'BILLING_PAYMENTMETHODS_CLEAR',
    fetching: 'BILLING_PAYMENTMETHODS_FETCHING',
    fetchingDone: 'BILLING_PAYMENTMETHODS_FETCHING_DONE',
    receive: 'BILLING_PAYMENTMETHODS_RECEIVE',
    remove: 'BILLING_PAYMENTMETHODS_REMOVE',
  },
  stripe: {
    creating: 'CREATING_SETUP_INTENT',
    creatingDone: 'CREATING_SETUP_INTENT_DONE',
    receive: 'RECEIVE_SETUP_INTENT',
  },
};

export const Sounds = {
  clearMusic: 'MUSIC_SOUNDS_CLEAR',
  clearPrompts: 'PROMPT_SOUNDS_CLEAR',
  fetching: 'SOUNDS_FETCHING',
  fetchingDone: 'SOUNDS_FETCHING_DONE',
  receiveMusic: 'MUSIC_SOUNDS_RECEIVE',
  receivePrompts: 'PROMPT_SOUNDS_RECEIVE',
  removeMusic: 'MUSIC_SOUNDS_REMOVE',
  removePrompt: 'PROMPT_SOUNDS_REMOVE',
  saving: 'SOUNDS_SAVING',
  savingDone: 'SOUNDS_SAVING_DONE',
};

export const SharedMailboxes = {
  clear: 'SHAREDMAILBOXES_CLEAR',
  fetching: 'SHAREDMAILBOXES_FETCHING',
  fetchingDone: 'SHAREDMAILBOXES_FETCHING_DONE',
  receive: 'SHAREDMAILBOXES_RECEIVE',
  remove: 'SHAREDMAILBOXES_REMOVE',
  saving: 'SHAREDMAILBOXES_SAVING',
  savingDone: 'SHAREDMAILBOXES_SAVING_DONE',
  updateValidity: 'SHAREDMAILBOXES_UPDATE_VALIDITY',
};

export const Trunks = {
  clear: 'TRUNKS_CLEAR',
  fetching: 'TRUNKS_FETCHING',
  fetchingDone: 'TRUNKS_FETCHING_DONE',
  receive: 'TRUNKS_RECEIVE',
  remove: 'TRUNKS_REMOVE',
  saving: 'TRUNKS_SAVING',
  savingDone: 'TRUNKS_SAVING_DONE',
  updateValidity: 'TRUNKS_UPDATE_VALIDITY',
};

export const LinkedUser = {
  clear: 'LINKEDUSER_CLEAR',
  fetching: 'LINKEDUSER_FETCHING',
  fetchingDone: 'LINKEDUSER_FETCHING_DONE',
  receive: 'LINKEDUSER_RECEIVE',
  saving: 'LINKEDUSER_SAVING',
  savingDone: 'LINKEDUSER_SAVING_DONE',
  remove: 'LINKEDUSER_REMOVE',
  updateValidity: 'LINKEDUSER_UPDATE_VALIDITY',
  active: {
    clear: 'LINKEDUSER_ACTIVE_CLEAR',
    fetching: 'LINKEDUSER_ACTIVE_FETCHING',
    fetchingDone: 'LINKEDUSER_ACTIVE_FETCHING_DONE',
    receive: 'LINKEDUSER_ACTIVE_RECEIVE',
    saving: 'LINKEDUSER_ACTIVE_SAVING',
    savingDone: 'LINKEDUSER_ACTIVE_SAVING_DONE',
  },
};

export const ForwardingRules = {
  clear: 'FORWARDINGRULES_CLEAR',
  fetching: 'FORWARDINGRULES_FETCHING',
  fetchingDone: 'FORWARDINGRULES_FETCHING_DONE',
  receive: 'FORWARDINGRULES_RECEIVE',
  remove: 'FORWARDINGRULES_REMOVE',
  saving: 'FORWARDINGRULES_SAVING',
  savingDone: 'FORWARDINGRULES_SAVING_DONE',
};

export const Registrations = {
  clear: 'REGISTRATIONS_CLEAR',
  fetching: 'REGISTRATIONS_FETCHING',
  fetchingDone: 'REGISTRATIONS_FETCHING_DONE',
  receive: 'REGISTRATIONS_RECEIVE',
};

export const CreditStatus = {
  clear: 'CREDITSTATUS_CLEAR',
  fetching: 'CREDITSTATUS_FETCHING',
  fetchingDone: 'CREDITSTATUS_FETCHING_DONE',
  receive: 'CREDITSTATUS_RECEIVE',
};

export const CallBundles = {
  clear: 'CALLBUNDLES_CLEAR',
  fetchingActive: 'ACTIVE_CALLBUNDLES_FETCHING',
  fetchingActiveDone: 'ACTIVE_CALLBUNDLES_FETCHING_DONE',
  receiveActive: 'ACTIVE_CALLBUNDLES_RECEIVE',
  saving: 'CALLBUNDLES_SAVING',
  savingDone: 'CALLBUNDLES_SAVING',
  // eslint-disable-next-line sort-keys
  fetchingExpired: 'EXPIRED_CALLBUNDLES_FETCHING',
  fetchingExpiredDone: 'EXPIRED_CALLBUNDLES_FETCHING_DONE',
  receiveExpired: 'EXPIRED_CALLBUNDLES_RECEIVE',
};

export const Phonebook = {
  clear: 'PHONEBOOK_ENTRY_CLEAR',
  fetching: 'PHOENBOOK_ENTRY_FETCHING',
  fetchingDone: 'PHONEBOOK_ENTRY_FETCHING_DONE',
  receive: 'PHONEBOOK_ENTRY_RECEIVE',
  remove: 'PHONEBOOK_ENTRY_REMOVE',
  saving: 'PHONEBOOK_ENTRY_SAVING',
  savingDone: 'PHONEBOOK_ENTRY_SAVING_DONE',
};

export const Permissions = {
  clear: 'PERMISSIONS_CLEAR',
  fetching: 'PERMISSIONS_FETCHING',
  fetchingDone: 'PERMISSIONS_FETCHING_DONE',
  receive: 'PERMISSIONS_RECEIVE',
  saving: 'PERMISSIONS_SAVING',
  savingDone: 'PERMISSIONS_SAVING_DONE',
};

export const Limits = {
  clear: 'LIMITS_CLEAR',
  fetching: 'LIMITS_FETCHING',
  fetchingDone: 'LIMITS_FETCHING_DONE',
  receive: 'LIMITS_RECEIVE',
  saving: 'LIMITS_SAVING',
  savingDone: 'LIMITS_SAVING_DONE',
};

export const Pricing = {
  clear: 'PRICING_CLEAR',
  fetching: 'PRICING_FETCHING',
  fetchingDone: 'PRICING_FETCHING_DONE',
  receive: 'PRICING_RECEIVE',
  saving: 'PRICING_SAVING',
  savingDone: 'PRICING_SAVING_DONE',
};
export const CallCharging = {
  clear: 'CALLCHARGING_CLEAR',
  fetching: 'CALLCHARGING_FETCHING',
  fetchingDone: 'CALLCHARGING_FETCHING_DONE',
  receive: 'CALLCHARGING_RECEIVE',
  saving: 'CALLCHARGING_SAVING',
  savingDone: 'CALLCHARGING_SAVING_DONE',
  // eslint-disable-next-line sort-keys
  chargingPlans: {
    clear: 'CHARGINGPLANS_CLEAR',
    fetching: 'CHARGINGPLANS_FETCHING',
    fetchingDone: 'CHARGINGPLANS_FETCHING_DONE',
    receive: 'CHARGINGPLANS_RECEIVE',
  },
};

export const RoutingRules = {
  clear: 'ROUTINGRULES_CLEAR',
  fetching: 'ROUTINGRULES_FETCHING',
  fetchingDone: 'ROUTINGRULES_FETCHING_DONE',
  receive: 'ROUTINGRULES_RECEIVE',
  remove: 'ROUTINGRULES_REMOVE',
  saving: 'ROUTINGRULES_SAVING',
  savingDone: 'ROUTINGRULES_SAVING_DONE',
  update: 'ROUTINGRULES_UPDATE',
};

export const Preferences = {
  clear: 'PREFERENCES_CLEAR',
  fetching: 'PREFERENCES_FETCHING',
  fetchingDone: 'PREFERENCES_FETCHING_DONE',
  receive: 'PREFERENCES_RECEIVE',
  saving: 'PREFERENCES_SAVING',
  savingDone: 'PREFERENCES_SAVING_DONE',
};

export const CallRecordings = {
  clear: 'CALLRECORDINGS_CLEAR',
  fetching: 'CALLRECORDINGS_FETCHING',
  fetchingDone: 'CALLRECORDINGS_FETCHING_DONE',
  receive: 'CALLRECORDINGS_RECEIVE',
  remove: 'CALLRECORDINGS_REMOVE',
};

export const BargeGroups = {
  clear: 'BARGEGROUPS_CLEAR',
  fetching: 'BARGEGROUPS_FETCHING',
  fetchingDone: 'BARGEGROUPS_FETCHING_DONE',
  receive: 'BARGEGROUPS_RECEIVE',
  remove: 'BARGEGROUPS_REMOVE',
  saving: 'BARGEGROUPS_SAVING',
  savingDone: 'BARGEGROUPS_SAVING_DONE',
};

export const Countries = {
  fetching: 'COUNTRIES_FETCHING',
  fetchingDone: 'COUNTRIES_FETCHING_DONE',
  receive: 'COUNTRIES_RECEIVE',
};

export const BlockingRules = {
  clear: 'BLOCKINGRULES_CLEAR',
  fetching: 'BLOCKINGRULES_FETCHING',
  fetchingDone: 'BLOCKINGRULES_FETCHING_DONE',
  receive: 'BLOCKINGRULES_RECEIVE',
  remove: 'BLOCKINGRULES_REMOVE',
  saving: 'BLOCKINGRULES_SAVING',
  savingDone: 'BLOCKINGRULES_SAVING_DONE',
  updateValidity: 'BLOCKINGRULES_UPDATE_VALIDITY',
};

export const Provisioning = {
  addDevice: 'PROVISIONING_ADD_DEVICE',
  addDeviceDone: 'PROVISIONING_ADD_DEVICE_DONE',
  clear: 'PROVISIONING_CLEAR',
  editDevice: 'PROVISIONING_EDIT_DEVICE',
  editDeviceDone: 'PROVISIONING_EDIT_DEVICE_DONE',
  fetching: 'PROVISIONING_FETCHING',
  fetchingDevices: 'PROVISIONING_FETCHING_DEVICES',
  fetchingDevicesDone: 'PROVISIONING_FETCHING_DEVICES_DONE',
  fetchingDone: 'PROVISIONING_FETCHING_DONE',
  receive: 'PROVISIONING_RECEIVE',
  receiveDevices: 'PROVISIONING_RECEIVE_DEVICES',
  removeDevice: 'PROVISIONING_REMOVE_DEVICE',
  removeDeviceDone: 'PROVISIONING_REMOVE_DEVICE_DONE',
  removeDeviceSuccess: 'PROVISIONING_REMOVE_DEVICE_SUCCESS',
  validationFailed: 'PROVISIONING_VALIDATION_FAILED',
};
